import client from '../lib/http-client';
import { buildQuery } from '../lib/helper';

const endpoint = '/participants';


export default {
  // Create
  create(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // register
  register(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}/register`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get List
  getList(params, cb, errorCb) {
    const url = endpoint;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url, { params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Bulk Delete
  bulkDelete(ids, cb, errorCb) {
    const params = {
      participant_ids: JSON.stringify(ids),
      // is_deleted: 1,
    };
    const url = `${endpoint}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.delete(url, { data: params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Update
  update(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },


  // Delete
  delete(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  deleteProfile(cb, errorCb) {
    const url = `${endpoint}/profile`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Bulk Create
  bulkCreate(eventId, participants, cb, errorCb) {
    const url = `${endpoint}/${eventId}/bulk`;
    const params = {
      participants: JSON.stringify(participants),
    };
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Bulk Update
  bulkUpdate(params, cb, errorCb) {
    const url = `${endpoint}/bulk/update`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.put(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Export Participants
  exportList(type, params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}/export/${type}?${query}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Check-in
  checkIn(params, cb, errorCb) {
    const url = `${endpoint}/checkIn`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get my participant
  me(params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}/me?${query}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },
  saveProfile(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}/update`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },
  // Verify code
  verifyCode(params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}/verifyCode?${query}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },
  cancelSeat(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}/cancelSeat`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },
  confirmSeat(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}/confirmSeat`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },
  generateQR(params, cb, errorCb) {
    const url = `${endpoint}/qrcode/generate`;
    client.post(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },
};
