<template>
	<div
    v-if="!isConfirmSuccess"
		class="vx-row h-screen lg:flex w-full no-gutter"
		id="page-register"
		>
    <!-- confirm seat -->
    <div class="text-left vx-col h-full w-full lg:m-0 p-6">
			<div class="flex justify-center items-center h-full w-full">
				<div class="px-10 max-w-lg mx-auto login-wrapper w-full">
          <div class="max-w-lg">
            <div class="space-y-6">
              <div class="text-xl">
                <!-- {{ $t('Confirmation your seat reservation on') }}
                <div class="text-4xl">{{ event && event.name }}</div> -->
                <!-- <vs-button id="save-changes-homepage" @click="confirmSeat()" class="save-btn-settings mt-10 mr-6" >{{$t('Confirm My Seat Reservation')}}</vs-button>
                <vs-button id="save-changes-homepage" @click="backToEvent()" color="#797979" class="mt-10" >{{$t('Cancel')}}</vs-button> -->
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
  <div class="page-event-live" v-else>
   <!-- register -->
   <RegisterContainer
    v-if="!isFetching"
    :event="event"
    :eventSettings="eventSettings"
    :dataConfirm="dataConfirm">
  </RegisterContainer>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import noLocale from '@/lib/locale/no';
import relativeTime from 'dayjs/plugin/relativeTime';

import participantApi from '@/api/participant';
import { getAxiosErrorMessage } from '@/lib/helper';
import eventsApi from '@/api/event';
import RegisterContainer from '@/components/events/RegisterContainer.vue';
import client from '../../lib/http-client';

dayjs.locale('en');
dayjs.extend(relativeTime);

export default {
  props: {
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        company: '',
        is_attending_to_venue: false,
        notes: '',
      },
      isConfirming: false,
      event: null,
      eventSettings: null,
      isFetching: false,
      isConfirmSuccess: false,
      dataConfirm: null,
    };
  },
  components: {
    RegisterContainer,
  },
  created() {
    this.fetchEvent();
  },
  methods: {
    fetchEvent() {
      this.$vs.loading();
      this.isFetching = true;
      const callback = (response) => {
        this.event = response.data;
        const settings = this.event && this.event.settings ? JSON.parse(this.event.settings) : '';
        this.eventSettings = settings;
        if (this.event) {
          // set language
          client.defaults.headers['Accept-Language'] = response.data.language || 'no';
          this.$i18n.locale = response.data.language || 'no';
          this.$store.dispatch('setLocale', response.data.language || 'no');
          this.confirmSeat();
          this.isFetching = false;
        }
      };
      const errorCallback = () => {
        this.$vs.loading.close();
        this.$router.push('/error-404');
        this.isFetching = false;
      };
      eventsApi.get(this.eventId, callback, errorCallback);
    },
    confirmSeat() {
      this.isConfirmSuccess = false;
      this.isConfirming = true;
      this.$vs.loading();
      const notifTitle = this.$t('Confirm Seat');
      const params = {
        email: this.email,
      };
      const callback = (response) => {
        this.isConfirming = false;
        const data = response.message;
        if (this.event && this.event.link_code) {
          this.dataConfirm = data;
          setTimeout(() => {
            this.isConfirmSuccess = true;
          }, 500);
        }
        this.$vs.loading.close();
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$router.push('/error-404');
        this.isConfirming = false;
        this.$vs.loading.close();
      };
      participantApi.confirmSeat(this.event.id, params, callback, errorCallback);
    },
    backToEvent() {
      const code = this.event && this.event.link_code_value ? this.event.link_code_value : null;
      this.$router.push(`/c/${code}`);
    },
    async getEventByCode(dataCode) {
      this.isFetching = true;
      this.$vs.loading();
      const callback = (response) => {
        const data = response.data;

        this.isFetching = false;
        this.$vs.loading.close();

        // Set event
        const eventData = data.event;
        if (eventData) {
          localStorage.removeItem(eventData.slug);
          if (!this.isFromRegister) {
            this.$router.push(`/c/${eventData.link_code_value}?code=${dataCode}`);
          } else {
            this.$emit('refetchLandingPage', dataCode);
          }
          this.resetForm();
        }
      };
      const errorCallback = (response) => {
        this.$vs.loading.close();
        this.isFetching = false;
        const message = getAxiosErrorMessage(response);
        if (message) {
          this.$vs.notify({
            title: this.$t('Access'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        }
      };
      eventsApi.getByCode(dataCode, callback, errorCallback);
    },
  },
  computed: {
    formatEventTime() {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');

      const timeLabel = this.event && this.event.start_date ? dayjs(this.event.start_date).format('dddd DD MMMM YYYY') : '';
      return timeLabel;
    },
    isHybrid() {
      return this.event && this.event.is_hybrid ? this.event.is_hybrid : false;
    },
    isAttend() {
      return this.form.is_attending_to_venue;
    },
    isFormValid() {
      return this.form.name !== '' && this.form.email !== '';
    },
    email() {
      return this.$route.query.email;
    },
    eventId() {
      return this.$route.params.eventId;
    },

  },
  mounted() {
  },
  watch: {
    isAttend() {
      if (this.isAttend) this.form.notes = '';
    },
  },
};
</script>

<style lang="scss">
</style>
