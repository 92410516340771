import client from '../lib/http-client';

const endpoint = '/events';

export default {
  // Create
  create(params, cb, errorCb) {
    const url = endpoint;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get by id
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get by code
  getByCode(code, cb, errorCb) {
    const url = `${endpoint}/${code}/code`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get by slug
  getBySlug(slug, cb, errorCb) {
    const url = `${endpoint}/${slug}/slug`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get by link code
  getByLinkCode(linkCode, cb, errorCb) {
    const url = `${endpoint}/${linkCode}/link`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get List
  getList(params, cb, errorCb) {
    const url = endpoint;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url, { params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // get event statistic
  getStatistic(eventId, cb, errorCb) {
    const url = `${endpoint}/${eventId}/statistic`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Viewer event
  getViewer(slug, params, cb, errorCb) {
    const url = `${endpoint}/${slug}/viewers`;
    client.get(url, { params })
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Bulk Delete
  bulkDelete(ids, cb, errorCb) {
    const params = {
      ids: JSON.stringify(ids),
      // is_deleted: 1,
    };
    const url = `${endpoint}/bulkDelete`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.delete(url, { data: params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Update user
  update(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Delete
  delete(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  bulkCreate(users, cb, errorCb) {
    const url = `${endpoint}/bulk/create`;
    const params = { users: JSON.stringify(users) };
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Export
  exportList(format, cb, errorCb) {
    const url = `${endpoint}/export/${format}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Create
  inviteBulk(params, cb, errorCb) {
    const url = `${endpoint}/invitation`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Join
  join(params, cb, errorCb) {
    const url = `${endpoint}/${params.event_slug}/join`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },
  // Create user by nickname
  createUserByNickname(eventSlug, params, cb, errorCb) {
    const url = `${endpoint}/${eventSlug}/nickname`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Duplicate
  duplicate(id, cb, errorCb) {
    const url = `${endpoint}/${id}/duplicate`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Scanner
  qrScanner(params, cb, errorCb) {
    const url = `${endpoint}/qrScanner`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Scanner
  qrExport(id, cb, errorCb) {
    console.log(id);
    const url = `${endpoint}/${id}/qrcode/export`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },
};
